import { React } from "react";
import RoundedSection from "../roundedSection";
import { Link } from "react-router-dom";

export function Services(props) {
    return (
        <RoundedSection currentColor="bg-white" nextColor="bg-athens">
            <div className="container w-full">
                <div className="flex flex-col w-full py-8">
                    <div className=" text-bluemine w-[100%] justify-center font-bold text-5xl">
                        Servicios
                    </div>
                    <div className="text-black text-2xl w-[100%] py-8">
                        En su consultorio, la Dra. CastroEn su consultorio, la Dra. CastroEn su consultorio, la Dra. CastroEn su consultorio, la Dra. Castro En su consultorio, la Dra. Castro
                    </div>
                </div>
                <div className=" px-24 py-2 flex flex-row space-x-12 justify-center">
                    <div className="w-[80%] h-[50%] ml-11 flex flex-col justify-center items-center">
                        <img className="w-[80%] h-[50%] border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./dentist-profile.png" alt="logo"></img>
                        <p className="pt-4 justify-center text-xl font-bold">Coronas en Emax/Zirconium</p>
                    </div>
                    <div className="w-[80%] h-[50%]  flex flex-col justify-center items-center">
                        <img className="w-[80%] h-[50%] border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./dentist-profile.png" alt="logo"></img>
                        <p className="pt-4 justify-center text-xl font-bold">Endodoncia</p>
                    </div>
                    <div className="w-[80%] h-[50%]  flex flex-col justify-center items-center">
                        <img className="w-[80%] h-[50%] border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./dentist-profile.png" alt="logo"></img>
                        <p className="pt-4 justify-center text-xl font-bold">Blanqueamiento dental</p>
                    </div>
                    <div className="w-[80%] h-[50%]  flex flex-col justify-center items-center">
                        <img className="w-[80%] h-[50%] border-l-[10px] border-calypso border-t-[10px] rounded-3xl no-drag" src="./dentist-profile.png" alt="logo"></img>
                        <p className="pt-4 justify-center text-xl font-bold">Prótesis</p>
                    </div>
                </div>
                <div className="flex justify-center py-14">
                    <Link to="/servicios">
                        <button className="bg-calypso text-white font-bold p-5 px-8 rounded-3xl">Ver todos los servicios</button>
                    </Link>
                </div>
            </div>
        </RoundedSection>
    )
}