import { React, Component } from "react";
import RoundedSection from "../roundedSection";
import { Link } from "react-router-dom";

export default class Navbar extends Component {
    render() {
        return (
            <RoundedSection currentColor="bg-white" nextColor="bg-athens">
                <div className="p-8 flex flex-row items-center justify-center space-x-24">
                    <Link to={"/home"}>
                        <div className="flex flex-row items-center">
                            <img className="no-drag pr-2 w-[15%]" src="./drpc-logo.svg" alt="logo"></img>
                            <p className="font-bold text-3xl text-bluemine">Dra. Patricia Castro</p>
                        </div>
                    </Link>
                    <div className="flex flex-row space-x-44">
                        <Link to={"/sobre"}>
                            <p className="text-xl text-bluemine font-bold hover:text-calypso">Acerca de</p>
                        </Link>
                        <Link to={"/servicios"}>
                            <p className="text-xl text-bluemine font-bold hover:text-calypso">Servicios</p>
                        </Link>
                        <Link to={"/contacto"}>
                            <p className="text-xl text-bluemine font-bold hover:text-calypso">Contacto</p>
                        </Link>
                    </div>
                </div>
            </RoundedSection>
        )
    }
}