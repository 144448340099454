import { React, Component } from "react";
import ImageFrame from "../imageFrame";
import RoundedSection from "../roundedSection";
import { Link } from "react-router-dom";

export default class LandingHeader extends Component {
    render() {
        return (
            <RoundedSection currentColor="bg-athens">
                <div className="container p-14">
                    <div className="flex flex-row justify-center space-x-8">
                        <ImageFrame src="dentist-profile.png" class="xl:w-[40%] 2xl:w-[30%]" />
                        <div className="flex flex-col xl:w-[50%] 2xl:w-[41%]">
                            <p className="font-bold text-7xl text-bluemine pt-2">Dra. Patricia Castro Gomez</p>
                            <div className="flex flex-col px-5 py-8 my-3 bg-bluemine rounded-[40px]">
                                <div className="flex flex-col text-center pb-4">
                                    <p className="font-bold text-4xl text-white">Odontóloga con Especialidad</p>
                                    <p className="font-bold text-4xl text-white">en Endodoncia</p>
                                </div>
                                <div className="flex flex-row justify-center space-x-8">
                                    <div className="relative">
                                        <img className=" w-28" src="muela-vector.png" alt="logo" />
                                        <p className="text-white absolute top-[35%] left-[20%]">Limpieza</p>
                                    </div>
                                    <div className="relative">
                                        <img className=" w-28" src="muela-vector.png" alt="logo" />
                                        <p className="text-white absolute top-[35%] left-[26%]">Cirugía</p>
                                    </div>
                                    <div className="relative">
                                        <img className=" w-28" src="muela-vector.png" alt="logo" />
                                        <p className="text-white absolute top-[35%] left-[25%]">Prótesis</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <Link to="/contacto">
                                <div className="flex flex-col items-center bg-calypso rounded-3xl p-5 px-8">
                                    <p className="text-white text-xl">Agenda tu cita aquí</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </RoundedSection>
        )
    }
}