import { React, Component } from "react";
import './index.css';

export default class ImageFrame extends Component {
    render() {
        return (
            <div className={this.props.class}>
                <div className="inline-block relative dra-perfil">
                    <img className="" src={this.props.src} alt="profile-drp" />
                </div>
            </div>
        )
    }
}