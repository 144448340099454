import { React, Component } from "react";
import Schedule from "../../components/schedule";
import RoundedSection from "../../components/roundedSection";
import ImageFrame from "../../components/imageFrame";


export default class About extends Component {
    render() {
        return (
            <>
                <RoundedSection currentColor="bg-athens" nextColor="bg-white">
                    <div className="container flex justify-center space-x-8 p-12">
                        <div className="flex flex-col w-[35%]">
                            <p className="text-bluemine font-bold text-4xl text-right">Acerca de</p>
                            <p className="text-calypso font-bold text-6xl text-left py-3">La Dra. Patricia Castro Gomez</p>
                            <p className="text-justify pb-3 text-lg">Nacida en la ciudad de Guayaquil, la Dra. Castro demostró desde 
                                                        temprana edad un gusto por el cuidado de los demás. Su pasión la llevó a estudiar Odontología en la Universidad Estatal de Guayaquil.
                            </p>
                            <p className="text-justify pb-3 text-lg">Para su especialidad, decidió estudiar en la Facultad de Odontología de Piracicaba de la Universidad de Campinas, São Paulo en donde obtuvo el título de Especialista en Endodoncia.</p>
                            <p className="text-justify pb-3 text-lg">Definida como una persona con un carácter amable y respetuoso, gusta de la jardinería, lectura, música, y de servir a los demás, siempre ha buscado actualizarse para brindar a sus pacientes la mejor atención.</p>
                            <p className="text-justify text-lg">De relgión católica, pertenece al Movimiento de Schoenstatt en Ecuador.</p>
                        </div>
                        <ImageFrame class="w-[34%]" src="drpc-about.png"/>
                    </div>
                </RoundedSection>
                <Schedule currentColor="bg-white" nextColor="bg-bluemine"/>
            </>

        )
    }
}