import { React, Component } from "react";

export default class Footer extends Component {
    render() {
        return (
            <footer className="bg-bluemine flex justify-center">
                <div className="container flex flex-row items-center p-10">
                    <div className="flex flex-row justify-center py-10 border-r-2  border-white w-1/2">
                        <img className="pr-2" src="muela.svg" alt="muela" />
                        <div className="flex flex-col">
                            <p className="text-3xl font-bold text-white">Dra. Patricia Castro, Odont.</p>
                            <p className="font-bold text-xl text-white">E-mail: <a className="hover:underline" href="mailto:patycastro3@hotmail.com">patycastro3@hotmail.com</a></p>
                        </div>
                    </div>
                    <div className="p-5 pl-12 w-1/2">
                        <p className="text-white text-lg font-bold">Telf.: 2283178</p>
                        <p className="text-white text-lg font-bold">Cel.: 0985895707</p>
                        <p className="text-white text-lg font-bold">Ubicación: Clínica Kennedy, Edificio Epsilon 2do piso,</p>
                        <p className="text-white text-lg font-bold">Oficina #2, Av. San Jorge 424</p>
                    </div>
                </div>
            </footer>
        )
    }
}