import { React, Component } from "react";
import "./index.css";
import RoundedSection from "../roundedSection";
import { Link } from "react-router-dom";

export default class Schedule extends Component {
    render() {
        return (
            <RoundedSection nextColor={this.props.nextColor} currentColor={this.props.currentColor}>
                <div className="container flex flex-col items-center p-16">
                    <p className="text-bluemine text-4xl font-bold">¡Agenda tu visita!</p>
                    <div className="flex flex-row justify-center space-x-10 pt-4">
                        <img className="w-[15%]" src="muela-bluemine.svg" alt="muela" />
                        <div className="">
                            <p className="text-2xl text-center font-bold text-calypso border-2 border-b-0 rounded-t-xl border-calypso p-2">Horario de atención</p>
                            <table className="">
                                <tr>
                                    <td>Lunes</td>
                                    <td>10h00am - 15h00pm</td>
                                </tr>
                                <tr>
                                    <td>Martes</td>
                                    <td>10h00am - 15h00pm</td>
                                </tr>
                                <tr>
                                    <td>Miércoles</td>
                                    <td>10h00am - 15h00pm</td>
                                </tr>
                                <tr>
                                    <td>Jueves</td>
                                    <td>10h00am - 15h00pm</td>
                                </tr>
                                <tr>
                                    <td>Viernes</td>
                                    <td >10h00am - 15h00pm</td>
                                </tr>
                            </table>
                        </div>
                        <img className="w-[15%]" src="muela-bluemine.svg" alt="muela" />
                    </div>
                    <div className="flex flex-col items-center pt-5">
                        <Link to="/contacto">
                            <button className="flex flex-col items-center bg-calypso rounded-3xl p-5 px-8">
                                <p className="text-white text-xl">Agenda tu cita aquí</p>
                            </button>
                        </Link>
                    </div>
                </div>
            </RoundedSection>
        )
    }
}