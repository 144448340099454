import { React } from "react";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import RoundedSection from "../roundedSection";

export function CasoExito(props) {
    return (
        <RoundedSection currentColor="bg-athens" nextColor="bg-white">
            <div className="container w-full h-[10%]">
                <div className="flex flex-col w-full">
                    <p className="text-5xl font-bold text-bluemine py-8 ">Caso de éxito</p>
                </div>
                <div className="flex flex-col pl-16 text-2xl justify-center items-center">
                    <p>En su consultorio, la Dra. CastroEn su consultorio, la Dra. CastroEn su consultorio, la Dra. CastroEn su consultorio, la
                        Dra. Castro En su consultorio, la Dra. Castro
                    </p>
                </div>
                {/*el h con porcentajes no me funcionaba, no se xq, asi que por el momento lo deje en px, pero deberia de ser en porcentajes*/}
                <div className="flex flex-col justify-center items-center w-full ">
                    <div className="flex flex-row justify-center items-center w-[75%] space-x-16 py-8">
                        <div className="w-[100%] h-[400px] flex flex-col items-center">
                            <ReactCompareSlider className="w-[100%] h-[100%]  border-8 border-bluemine"
                                itemOne={<ReactCompareSliderImage src="./dentist-profile.png" alt="Image one" />}
                                itemTwo={<ReactCompareSliderImage src="./drpc-logo.svg" alt="Image two" />}
                            />
                            <p className="text-lg font-bold pt-8">Lorem Ipsum</p>
                        </div>
                        <div className="w-[100%] h-[400px] flex flex-col items-center">
                            <ReactCompareSlider className="w-[100%] h-[100%] border-8 border-bluemine"
                                itemOne={<ReactCompareSliderImage src="./dentist-profile.png" alt="Image one" />}
                                itemTwo={<ReactCompareSliderImage src="./drpc-logo.svg" alt="Image two" />}
                            />
                            <p className="text-lg font-bold pt-8">Lorem Ipsum</p>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center w-[75%] space-x-16 py-8">
                        <div className="w-[100%] h-[400px] flex flex-col items-center">
                            <ReactCompareSlider className="w-[100%] h-[100%]  border-8 border-bluemine"
                                itemOne={<ReactCompareSliderImage src="./dentist-profile.png" alt="Image one" />}
                                itemTwo={<ReactCompareSliderImage src="./drpc-logo.svg" alt="Image two" />}
                            />
                            <p className="text-lg font-bold pt-8">Lorem Ipsum</p>
                        </div>
                        <div className="w-[100%] h-[400px] flex flex-col items-center">
                            <ReactCompareSlider className="w-[100%] h-[100%] border-8 border-bluemine"
                                itemOne={<ReactCompareSliderImage src="./dentist-profile.png" alt="Image one" />}
                                itemTwo={<ReactCompareSliderImage src="./drpc-logo.svg" alt="Image two" />}
                            />
                            <p className="text-lg font-bold pt-8">Lorem Ipsum</p>
                        </div>
                    </div>
                </div>
            </div>
        </RoundedSection>
    )
}