import {React, Component} from "react";
import RoundedSection from "../../components/roundedSection";

export default class Contacto extends Component {
    render(){
        return(
            <RoundedSection currentColor="bg-athens" nextColor="bg-white">
                <p>holi holi</p>
            </RoundedSection>
        )
    }
}